import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { usePost } from "../../hooks/usePost";

export default function useForgotPasswordLogic() {
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const onErrorCall = (error) => {
    setNotificationMessage(error?.response?.data?.data || error.message);
    setOpen(true);
  };

  const onSuccessFulCall = (data) => {
    queryClient.setQueryData("reset", data, {
      cacheTime: 10000,
      staleTime: 10000,
    });
    navigate(`/login/reset-password-email-sent`);
  };

  const { mutate, isLoading, isError, data, error } = usePost(
    onSuccessFulCall,
    onErrorCall
  );
  const onSubmit = (values, _event) => {
    mutate({
      url: "reset-password/email",
      data: { email: values.email },
      method: "post",
    });
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return {
    state: {
      register,
      errors,
      open,
      notificationMessage,
    },
    handlers: { handleSubmit, handleClose, onSubmit },
    fetchResponse: {
      isLoading,
      isError,
      data,
      error,
    },
  };
}
