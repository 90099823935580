function ArrowBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.992"
      height="23.136"
      viewBox="0 0 26.992 23.136"
    >
      <path
        id="Icon_metro-arrow-left"
        data-name="Icon metro-arrow-left"
        d="M14.7,28.356l-9.64-9.64a1.928,1.928,0,0,1,0-2.727l9.64-9.64A1.928,1.928,0,0,1,17.43,9.075l-6.349,6.349H29.563a1.928,1.928,0,1,1,0,3.856H11.081l6.349,6.349A1.928,1.928,0,0,1,14.7,28.356Z"
        transform="translate(-4.499 -5.784)"
        fill={"#625DF5"}
      />
    </svg>
  );
}

export default ArrowBack;
