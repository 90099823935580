function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.735"
      height="19.126"
      viewBox="0 0 16.735 19.126"
    >
      <path
        id="Icon_awesome-copy"
        data-name="Icon awesome-copy"
        d="M11.953,16.735v1.494a.9.9,0,0,1-.9.9H.9a.9.9,0,0,1-.9-.9V4.483a.9.9,0,0,1,.9-.9h2.69V14.643a2.094,2.094,0,0,0,2.092,2.092Zm0-12.85V0H5.678a.9.9,0,0,0-.9.9V14.643a.9.9,0,0,0,.9.9h10.16a.9.9,0,0,0,.9-.9V4.781H12.85A.9.9,0,0,1,11.953,3.885Zm4.519-1.159L14.009.263A.9.9,0,0,0,13.375,0h-.226V3.586h3.586V3.36a.9.9,0,0,0-.263-.634Z"
      />
    </svg>
  );
}

export default CopyIcon;
