import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import { CircularProgress, Box } from "@mui/material";
import Notification from "../Notification";
import { EmailInput } from "../Form/FormsInputs";
import classes from "./forgotpassword.module.css";
import { Link } from "react-router-dom";
import useForgotPasswordLogic from "./useForgotPasswordLogic";
// import ArrowBack from "../../assets/images/svg/ArrowBack";
import ArrowBackSmall from "../../assets/images/svg/ArrowBackSmall";

function ForgotPasswordForm() {
  const { state, handlers, fetchResponse } = useForgotPasswordLogic();
  const { register, errors, open, notificationMessage } = state;
  const { isLoading } = fetchResponse;
  const { handleSubmit, onSubmit, handleClose } = handlers;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
      className={classes.container}
    >
      <div>
        <span className={classes.signin}>Forgot password</span>
        <br />
        <span>Enter your contact email to proceed</span>
      </div>
      <EmailInput
        register={register}
        name="email"
        label="Contact Email"
        error={errors?.email}
        autoFocus
      />
      <Button
        type="submit"
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        disabled={isLoading}
        className={classes.button}
      >
        Submit
      </Button>
      <Link to={"/login"} className={classes.link}>
        <span>
          <ArrowBackSmall />
          {/* <ArrowBack color={true} width="26.992" height="23.136" /> */}
        </span>
        &nbsp;
        <span>Back to login</span>
      </Link>

      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: "absolute",
            top: "64%",
            left: "67%",
          }}
        />
      )}
      <Notification
        severity="error"
        message={notificationMessage}
        openNotification={open}
        handleClose={handleClose}
      />
    </Box>
  );
}

export default ForgotPasswordForm;
