import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDownTwo from "../../assets/images/svg/ArrowDownTwo";
import classes from "./selectbutton.module.css";

function SelectButton({
  selectedOption = {},
  anchorEl,
  open,
  handleButtonClick,
  handleMenuClose,
  itemList,
  handleSelect,
  paddingRight = 2,
  marginLeft = 0,
  borderWidth = true,
}) {
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        endIcon={<ArrowDownTwo />}
        className={borderWidth ? classes.options : classes.optionsWithoutBorder}
      >
        <span className={classes.text}>{selectedOption?.value}</span>
      </Button>
      <Menu
        elevation={5}
        marginThreshold={60}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        sx={{
          marginLeft,
        }}
      >
        {itemList.map((item) => {
          return (
            <MenuItem
              sx={{
                paddingRight,
              }}
              key={item.id}
              onClick={() => handleSelect(item)}
            >
              {item.value}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default SelectButton;
