import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { usePost } from "../../hooks/usePost";
import { useFetch } from "../../hooks/useFetch";

export default function useResetPasswordLogic() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const { keyword } = useParams();

  const onTokenError = (error) => {
    setNotificationMessage(
      error.response?.data?.data || "Expired or Invalid session"
    );
    setOpen(true);
  };

  const {
    isSuccess: isKeywordSuccess,
    isError: isKeywordError,
    isLoading: isKeywordLoading,
    data: keywordData,
    error: keywordError,
  } = useFetch(
    `reset-password/${keyword}`,
    true,
    {},
    30000,
    30000,
    () => null,
    onTokenError
  );

  const [expiryTimestamp, setExpiryTimestamp] = useState(0);
  //
  useEffect(() => {
    if (isKeywordSuccess) {
      setExpiryTimestamp(keywordData.data.data?.expiresIn);
    }
  }, [isKeywordSuccess, keywordData?.data?.data?.expiresIn]);

  useEffect(() => {
    if (isKeywordError || isKeywordLoading) return;
    if (expiryTimestamp <= 0) return;
    const countdownInterval = setInterval(() => {
      setExpiryTimestamp((prevSeconds) => prevSeconds - 1);
    }, 1000);
    // Clean up the interval when the component is unmounted
    return () => clearInterval(countdownInterval);
  }, [expiryTimestamp, isKeywordError, isKeywordLoading]);

  const onErrorCall = (error) => {
    setNotificationMessage(error.message || "Email does not exist");
    setOpen(true);
  };

  const onSuccessFulCall = (_data) => {
    setSuccess(true);
  };

  const { mutate, isLoading, isError, data, error } = usePost(
    onSuccessFulCall,
    onErrorCall
  );
  const onSubmit = (values, _event) => {
    if (values.password !== values.confirm) {
      setNotificationMessage("Password mismatch");
      setOpen(true);
      return;
    }
    mutate({
      url: `reset-password/${keyword}`,
      data: { password: values.password },
      method: "post",
    });
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return {
    state: {
      register,
      errors,
      open,
      notificationMessage,
    },
    handlers: { handleSubmit, handleClose, onSubmit },
    fetchResponse: {
      isLoading,
      isError,
      data,
      error,
    },
    keywordResponse: {
      isKeywordSuccess,
      isKeywordLoading,
      isKeywordError,
      keywordData,
      keywordError,
    },
    expiryTimestamp,
    success,
  };
}
