function FilePdf() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.274"
      height="28.366"
      viewBox="0 0 21.274 28.366"
    >
      <path
        id="Icon_awesome-file-pdf"
        data-name="Icon awesome-file-pdf"
        d="M10.078,14.189a5.345,5.345,0,0,1-.111-2.6C10.432,11.59,10.388,13.635,10.078,14.189ZM9.983,16.8A25.564,25.564,0,0,1,8.41,20.277a20.4,20.4,0,0,1,3.485-1.213A7.177,7.177,0,0,1,9.983,16.8ZM4.77,23.718c0,.044.731-.3,1.934-2.227A7.657,7.657,0,0,0,4.77,23.718ZM13.74,8.864h7.535V27.036a1.326,1.326,0,0,1-1.33,1.33H1.33A1.326,1.326,0,0,1,0,27.036V1.33A1.326,1.326,0,0,1,1.33,0H12.41V7.535A1.334,1.334,0,0,0,13.74,8.864ZM13.3,18.382A5.561,5.561,0,0,1,10.931,15.4a7.963,7.963,0,0,0,.343-3.557,1.388,1.388,0,0,0-2.648-.377,9.212,9.212,0,0,0,.449,4.266,52.024,52.024,0,0,1-2.26,4.754c-.006,0-.006.006-.011.006-1.5.77-4.078,2.465-3.019,3.767a1.722,1.722,0,0,0,1.191.554c.992,0,1.978-1,3.385-3.424a31.58,31.58,0,0,1,4.377-1.285,8.4,8.4,0,0,0,3.546,1.08,1.434,1.434,0,0,0,1.091-2.4c-.77-.753-3.008-.537-4.078-.4Zm7.59-12.565L15.457.388A1.329,1.329,0,0,0,14.515,0h-.332V7.091h7.091V6.754A1.325,1.325,0,0,0,20.887,5.817ZM16.781,19.961c.227-.15-.139-.659-2.371-.5C16.466,20.338,16.781,19.961,16.781,19.961Z"
        fill="#625df5"
      />
    </svg>
  );
}

export default FilePdf;
