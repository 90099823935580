export function useCardLogic() {
  const handleCopyClick = async (service) => {
    try {
      await navigator.clipboard.writeText(service.url);
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    }
  };

  return {
    handleCopyClick,
  };
}
