import { Link } from "react-router-dom";
import classes from "./card.module.css";
import ArrowLeftIcon from "../../assets/images/svg/ArrowLeftIcon";
import CopyIcon from "../../assets/images/svg/CopyIcon";
import { IconButton } from "@mui/material";
import { useCardLogic } from "./useCardLogic";

function ServiceCard({ service }) {
  const { handleCopyClick } = useCardLogic();
  return (
    <div className={classes.serviceContainer}>
      <div>
        <div className={classes.serviceSection}>
          <div className={classes.serviceKey}>Name of service</div>
          <div className={classes.serviceValue}>{service.name}</div>
        </div>
        <div className={classes.serviceSection}>
          <div className={classes.serviceKey}>Duration</div>
          <div className={classes.serviceValue}>{service.duration}</div>
        </div>
        <div className={classes.serviceSection}>
          <div className={classes.serviceKey}>URL</div>
          <div className={classes.serviceUrlValue}>
            <div className={classes.serviceIconBesideText}>{service.url}</div>
            <IconButton onClick={() => handleCopyClick(service)}>
              <CopyIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.serviceSection}>
          <div className={classes.serviceKey}>Created at</div>
          <div className={classes.serviceValue}>{service.time_created}</div>
        </div>
        <Link
          to={`/services/${service.keyword}/${service.name}`}
          className={classes.seeMoreLink}
        >
          <div className={classes.serviceIconBesideText}>
            See subscription details
          </div>
          <ArrowLeftIcon />
        </Link>
      </div>
      <div>
        <div className={classes.serviceSection}>
          <div className={classes.serviceKey}>Keyword</div>
          <div className={classes.serviceValue}>{service.keyword}</div>
        </div>
        <div className={classes.serviceSection}>
          <div className={classes.serviceKey}>Cost</div>
          <div className={classes.serviceValue}>GHS {service.amount}</div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
