import { IconButton, Menu, MenuItem } from "@mui/material";
import classes from "./header.module.css";
import { useHeaderLogic } from "./useHeaderLogic";
import { Logout } from "@mui/icons-material";
import UserIcon from "../../assets/images/svg/UserIcon";

function Header() {
  const {
    handleLogout,
    username,
    handleMenu,
    handleClose,
    anchorEl,
    servicename,
    keyword,
    headerText,
    headerTextComponent,
  } = useHeaderLogic();

  return (
    <div className={classes.container}>
      {keyword ? (
        <div className={classes.text}>{headerTextComponent(servicename)}</div>
      ) : (
        <p className={classes.text}>{headerText}</p>
      )}
      <p>
        <span className={classes.username}>{username}</span>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          className={classes.profile}
        >
          <UserIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose("profile")}>Profile</MenuItem>
          <hr />
          <MenuItem onClick={handleLogout}>
            <span className={classes.logout}>
              <Logout /> &nbsp;&nbsp; Log Out
            </span>
          </MenuItem>
        </Menu>
      </p>
    </div>
  );
}

export default Header;
