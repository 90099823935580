import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import CustomCard from "../../components/Card/CustomCard";
import AreaChart from "../../components/AreaChart/AreaChart";
import classes from "./home.module.css";
import { useHomeLogic } from "./useHomeLogic";

export default function Home() {
  const { duration, figures, labels, totalAmount, handleChange, queryResult } =
    useHomeLogic();
  return (
    <Grid container spacing={5}>
      <Grid item xs={6} sm={3}>
        <CustomCard
          header="Active subscribers"
          helperHeader="Currently engaged in service"
          color="#b8b5ff"
          iconColor="#6CF7A6"
          count={
            queryResult[1].isError
              ? queryResult[1].error?.response?.data?.data ||
                queryResult[1].error?.message
              : queryResult[1].data?.data?.data[0]?.total || 0
          }
          border="0px"
          arrow={true}
          duration={`For this ${duration}`}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomCard
          header="Inactive subscribers"
          helperHeader="Not receiving updates"
          color="#FDF2C1"
          iconColor="#FAB877"
          count={
            queryResult[2].isError
              ? queryResult[2].error?.response?.data?.data ||
                queryResult[2].error?.message
              : queryResult[2].data?.data?.data[0]?.total || 0
          }
          border="0px"
          arrow={true}
          duration={`For this ${duration}`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomCard
          header="Total subscribers"
          helperHeader="Opted in to receive content"
          color="#FFFFFF"
          count={
            queryResult[0].isError
              ? queryResult[0].error?.response?.data?.data ||
                queryResult[0].error?.message
              : queryResult[0].data?.data?.data[0]?.total || 0
          }
          border="1px solid #ebeaed"
          duration="&nbsp;"
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.graphHeader}>
          <div className={classes.revenue}>Revenue Overview</div>
          <div className={classes.amountContainer}>
            <div className={classes.amount}>GHS {totalAmount}</div>
            <div className={classes.duration}>This {duration}</div>
          </div>
          <FormControl size="small">
            <Select
              labelId="select-small-label"
              id="select-small"
              value={duration}
              onChange={handleChange}
              className={classes.options}
            >
              <MenuItem value="week">This week</MenuItem>
              <MenuItem value="month">This month</MenuItem>
            </Select>
          </FormControl>
        </div>
        <AreaChart
          duration={`Revenue graph for ${duration}`}
          figures={figures}
          labels={labels}
        />
      </Grid>
    </Grid>
  );
}
