import { useRevenueLogic } from "./useRevenueLogic";
import classes from "./revenue.module.css";
import Space from "../../components/Space";
import SelectButton from "../../components/SelectButton/SelectButton";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import AreaChart from "../../components/AreaChart/AreaChart";
import CustomCard from "../../components/Card/CustomCard";
import { Button, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
function Revenue() {
  const {
    selectedNetworkOption,
    networkOpen,
    handleNetworkSelect,
    networkAnchorEl,
    handleNetworkButtonClick,
    handleNetworkMenuClose,
    networkItemList,
    selectedServiceOption,
    serviceOpen,
    handleServiceSelect,
    serviceAnchorEl,
    handleServiceButtonClick,
    handleServiceMenuClose,
    serviceItemList,
    exportOpen,
    selectedExportOption,
    handleExportButtonClick,
    handleExportMenuClose,
    exportItemList,
    handleExportSelect,
    exportAnchorEl,
    labels,
    figures,
    totalAmount,
    revError,
    revIsError,
    revenueAmount,
    isRevSuccess,
    isRevLoading,
    //
    getFromAndToDate,
    fromDate,
    toDate,
    //
    handleRevenueDataFetch,
  } = useRevenueLogic();
  return (
    <div>
      <div className={classes.header}>
        <div className={classes.searchOptionLeft}>
          <SelectButton
            selectedOption={selectedServiceOption}
            open={serviceOpen}
            handleSelect={handleServiceSelect}
            anchorEl={serviceAnchorEl}
            handleButtonClick={handleServiceButtonClick}
            handleMenuClose={handleServiceMenuClose}
            itemList={serviceItemList}
            paddingRight={4}
            marginLeft={1}
          />
          <Space
            paddingLeft={0}
            paddingBottom={0}
            paddingRight={10}
            paddingTop={0}
          />
          <SelectButton
            selectedOption={selectedNetworkOption}
            open={networkOpen}
            handleSelect={handleNetworkSelect}
            anchorEl={networkAnchorEl}
            handleButtonClick={handleNetworkButtonClick}
            handleMenuClose={handleNetworkMenuClose}
            itemList={networkItemList}
            paddingRight={4}
          />
          <Space
            paddingLeft={0}
            paddingBottom={0}
            paddingRight={10}
            paddingTop={0}
          />
          <DateRangePicker getFromAndToDate={getFromAndToDate} />
          <Space
            paddingLeft={0}
            paddingBottom={0}
            paddingRight={10}
            paddingTop={0}
          />
          <Button
            className={classes.button}
            disabled={isRevSuccess}
            onClick={handleRevenueDataFetch}
          >
            Submit query{" "}
            {isRevLoading && <CircularProgress color="success" size={15} />}
          </Button>
        </div>
        <div>
          <SelectButton
            selectedOption={selectedExportOption}
            open={exportOpen}
            handleSelect={handleExportSelect}
            anchorEl={exportAnchorEl}
            handleButtonClick={handleExportButtonClick}
            handleMenuClose={handleExportMenuClose}
            itemList={exportItemList}
            paddingRight={10}
            marginLeft={2}
          />
        </div>
      </div>
      <Space paddingBottom={20} />
      <div>
        <p>
          Showing results for:
          {selectedServiceOption.id === "all"
            ? ` All ${selectedServiceOption?.value}`
            : ` ${selectedServiceOption?.value}`}
        </p>
        <i>
          <span className={classes.date}>
            {fromDate[0]} - {toDate[0]}
          </span>
          {selectedNetworkOption.id === "all"
            ? ` All ${selectedNetworkOption?.value}`
            : ` ${selectedNetworkOption?.value}`}
        </i>
      </div>
      <Space paddingBottom={20} />
      <Grid container columnSpacing={2}>
        <Grid item sm={4}>
          <CustomCard
            header="Revenue generated"
            color="#FFFFFF"
            count={
              revIsError
                ? revError?.response?.data?.data || revError?.message
                : revenueAmount.toFixed(3)
            }
            currency="GHS"
            border="1px solid #ebeaed"
          />
        </Grid>
        <Grid item sm={4}>
          <CustomCard
            header="Total Revenue"
            color="#FFFFFF"
            count={
              revIsError
                ? revError?.response?.data?.data || revError?.message
                : totalAmount?.toFixed(3)
            }
            currency="GHS"
            border="1px solid #ebeaed"
          />
        </Grid>
      </Grid>
      <Space paddingBottom={20} />

      <AreaChart figures={figures} labels={labels} />
    </div>
  );
}

export default Revenue;
