import { useQueries } from "react-query";
import { APISecuredAxios } from "../libs/axios/securedAxios";

const fetchData = (queryKey, data) => {
  if (Array.isArray(queryKey) && queryKey.length > 1) {
    const url = queryKey.join("/");
    return APISecuredAxios()({ method: "get", url, data });
  }
  return APISecuredAxios()({ method: "get", url: `${queryKey[0]}`, data });
};

export const useFetches = (
  itemList,
  enabled = false,
  data = {},
  cacheTime = 30000,
  staleTime = 30000
) => {
  return useQueries(
    itemList.map((item) => {
      return {
        queryKey: [item?.queryKey[0], item?.field],
        queryFn: () => fetchData(item?.queryKey, data),
        enabled,
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime,
        staleTime,
      };
    })
  );
};
