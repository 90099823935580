import React from "react";

function RevenueIcon({ fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.748"
      height="22.748"
      viewBox="0 0 13.091 23.136"
    >
      <path
        id="Icon_metro-money"
        data-name="Icon metro-money"
        fill={fill}
        stroke={stroke}
        d="M17.738,16.581c-2.918-.758-3.856-1.536-3.856-2.757,0-1.4,1.292-2.384,3.47-2.384,2.288,0,3.136,1.093,3.213,2.7h2.841a5.117,5.117,0,0,0-4.126-4.9V6.427H15.424V9.2c-2.494.546-4.5,2.153-4.5,4.64,0,2.969,2.461,4.447,6.041,5.309,3.22.771,3.856,1.9,3.856,3.1,0,.88-.623,2.294-3.47,2.294-2.648,0-3.7-1.189-3.83-2.7H10.688c.161,2.815,2.262,4.389,4.737,4.923v2.789H19.28V26.8c2.5-.482,4.5-1.928,4.5-4.569,0-3.638-3.123-4.884-6.041-5.649Z"
        transform="translate(-10.688 -6.427)"
      />
    </svg>
  );
}

export default RevenueIcon;
