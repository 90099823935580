function HomeIcon({ fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.748"
      height="22.748"
      viewBox="0 0 21.415 24.901"
    >
      <g
        id="Icon_feather-home"
        data-name="Icon feather-home"
        transform="translate(-3.5 -2)"
      >
        <path
          id="Path_2330"
          data-name="Path 2330"
          d="M4.5,10.55,14.207,3l9.707,7.55V22.415a2.157,2.157,0,0,1-2.157,2.157H6.657A2.157,2.157,0,0,1,4.5,22.415Z"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_2331"
          data-name="Path 2331"
          d="M13.5,28.621V18h6.472V28.621"
          transform="translate(-2.528 -2.72)"
          fill="#fff"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default HomeIcon;
