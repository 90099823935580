import HomeIcon from "../../assets/images/svg/HomeIcon";
import RevenueIcon from "../../assets/images/svg/RevenueIcon";
import ServiceIcon from "../../assets/images/svg/ServiceIcon";

export const routes = {
  USER: [
    { id: "", name: "Home", Icon: (props) => <HomeIcon {...props} /> },
    {
      id: "services",
      name: "Services",
      Icon: (props) => <ServiceIcon {...props} />,
    },
    {
      id: "revenue",
      name: "Revenue",
      Icon: (props) => <RevenueIcon {...props} />,
    },
  ],
};
