import { useCallback, useEffect, useState } from "react";
import "jspdf-autotable";
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useFetches } from "../../hooks/useFetches";
import { useFetch } from "../../hooks/useFetch";
import { useQueryClient } from "react-query";

export function useRevenueLogic() {
  const queryClient = useQueryClient();
  const [fromDate, setFromDate] = useState([
    moment().format("MMM Do YY"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [toDate, setToDate] = useState([
    moment().format("MMM Do YY"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [duration, setDuration] = useState("week");
  const [labels, setLables] = useState([]);
  const [figures, setFigures] = useState([]);
  const [revenueAmount, setRevenueAmount] = useState(0);
  const [fetchDataFlag, setFetchDataFlag] = useState(true);
  const [downloadFlag, setDownloadFlag] = useState(false);
  //
  const [networkOpen, setNetworkOpen] = useState(false);
  const [networkAnchorEl, setNetworkAnchorEl] = useState(null);
  const [networkItemList] = useState([
    { id: "all", value: "Networks" },
    { id: "mtn", value: "MTN" },
    { id: "vodafone", value: "Vodafone" },
    { id: "airteltigo", value: "AirtelTigo" },
  ]);
  const [selectedNetworkOption, setSelectedNetworkOption] = useState({
    id: "all",
    value: "Networks",
  });

  const [selectedServiceOption, setSelectedServiceOption] = useState({
    id: "all",
    keyword: "all",
    value: "Service",
  });

  const listOfEndpoints = [
    { queryKey: ["service"], field: "service" },
    { queryKey: ["revenue/paid"], field: "revenue" },
    {
      queryKey: [
        `revenue/${fromDate[1]}/${toDate[1]}/${duration}/?network=${selectedNetworkOption.id}&service=${selectedServiceOption.id}`,
      ],
      field: "revenueWithDate",
    },
  ];

  const queryResult = useFetches(
    listOfEndpoints,
    fetchDataFlag,
    {},
    1800000,
    1800000
  );

  const onDownloadRequestSuccess = (data) => {
    const dataToDownload = data?.data?.data?.map((data) => {
      return {
        "Subscriber ID": data.subscribers_id,
        Status: data.status,
        Amount: data.amount,
        Number: data.msisdn,
        "Time Created": data.time_created,
        "Time Updated": data.time_updated,
      };
    });
    const ws = XLSX.utils.json_to_sheet(dataToDownload);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      `data_${fromDate[1]}_${toDate[1]}_${selectedNetworkOption.id}_${selectedServiceOption.id}.xlsx`
    );

    console.log(dataToDownload);
  };
  const downloadDataKey = [
    `revenue/transactions/${fromDate[1]}/${toDate[1]}/?network=${selectedNetworkOption.id}&service=${selectedServiceOption.id}`,
  ];
  const downloadQueryResult = useFetch(
    downloadDataKey,
    downloadFlag,
    {},
    1800000,
    1800000,
    onDownloadRequestSuccess
  );

  const [serviceItemList, setServiceItemList] = useState([]);
  const isServiceSuccess = queryResult[0].isSuccess;
  const updateServiceItemList = useCallback(() => {
    if (isServiceSuccess) {
      const servicesList = queryResult[0]?.data.data?.data.map((item) => {
        return { id: item.id, keyword: item.keyword, value: item.name };
      });
      setServiceItemList([
        { id: "all", keyword: "all", value: "Service" },
        ...servicesList,
      ]);
    }
  }, [isServiceSuccess]);

  // Call the updateServiceItemList function to update the state
  useEffect(() => {
    updateServiceItemList();
  }, [updateServiceItemList]);

  const prepareData = async (dataToFormat) => {
    const revResult = await dataToFormat?.data?.data.reduce(
      ([days, amounts, totalAmount], { day, amount }) => {
        days.push(day);
        amounts.push(amount);
        totalAmount += parseFloat(amount);
        return [days, amounts, totalAmount];
      },
      [[], [], 0]
    );

    // Check if revResult is defined before accessing its properties
    if (revResult) {
      setLables(revResult[0]);
      setFigures(revResult[1]);
      setRevenueAmount(revResult[2]);
    }
  };
  const isRevSuccess = queryResult[2].isSuccess;

  useEffect(() => {
    if (isRevSuccess) {
      prepareData(queryResult[2].data);
      setFetchDataFlag(false);
      setDownloadFlag(false);
    }
  }, [isRevSuccess]);

  const [serviceOpen, setServiceOpen] = useState(false);
  const [serviceAnchorEl, setServiceAnchorEl] = useState(null);

  //export
  const [exportOpen, setExportOpen] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportItemList] = useState([{ id: "excel", value: "Excel" }]);
  const [selectedExportOption] = useState({
    id: "export",
    value: "Export",
  });

  // network begins here
  const handleNetworkButtonClick = (event) => {
    setNetworkAnchorEl(event.currentTarget);
    setNetworkOpen(true);
    setDownloadFlag(false);
  };

  const handleNetworkMenuClose = () => {
    setNetworkAnchorEl(null);
    setNetworkOpen(false);
    setDownloadFlag(false);
  };

  const handleNetworkSelect = (selectedOption) => {
    setSelectedNetworkOption(selectedOption);
    setNetworkAnchorEl(null);
    setNetworkOpen(false);
    setDownloadFlag(false);
  };

  //service begins here
  const handleServiceButtonClick = (event) => {
    setServiceAnchorEl(event.currentTarget);
    setServiceOpen(true);
    setDownloadFlag(false);
  };

  const handleServiceMenuClose = () => {
    setServiceAnchorEl(null);
    setServiceOpen(false);
    setDownloadFlag(false);
  };

  const handleServiceSelect = (selectedOption) => {
    setSelectedServiceOption(selectedOption);
    setServiceAnchorEl(null);
    setServiceOpen(false);
    setDownloadFlag(false);
  };

  //export begins here
  const handleExportButtonClick = (event) => {
    setExportAnchorEl(event.currentTarget);
    setExportOpen(true);
    setDownloadFlag(false);
  };

  const handleExportMenuClose = () => {
    setExportAnchorEl(null);
    setExportOpen(false);
    setDownloadFlag(false);
  };

  const handleExportSelect = () => {
    //check if data already exist. If yes, download and return
    const cachedData = queryClient.getQueryData(downloadDataKey);
    const isDataCached = Boolean(cachedData);
    if (isDataCached) {
      onDownloadRequestSuccess(cachedData);
      return;
    }
    setDownloadFlag(true);

    setExportAnchorEl(null);
    setExportOpen(false);
  };

  //setDates
  const formatDate = (date) => [
    moment(date).format("MMM Do YY"),
    moment(date).format().split("T")[0],
  ];
  const getFromAndToDate = (values) => {
    const { fromDate: from, toDate: to, duration: dur } = values;

    setDownloadFlag(false);
    setFromDate(formatDate(from));
    setToDate(formatDate(to));
    setDuration(dur);
  };

  const handleRevenueDataFetch = () => {
    setFetchDataFlag(true);
  };

  return {
    selectedNetworkOption,
    networkOpen,
    handleNetworkSelect,
    networkAnchorEl,
    handleNetworkButtonClick,
    handleNetworkMenuClose,
    networkItemList,
    selectedServiceOption,
    serviceOpen,
    handleServiceSelect,
    serviceAnchorEl,
    handleServiceButtonClick,
    handleServiceMenuClose,
    serviceItemList,
    exportOpen,
    exportAnchorEl,
    selectedExportOption,
    exportItemList,
    handleExportButtonClick,
    handleExportMenuClose,
    handleExportSelect,
    //
    revIsError: queryResult[1].isError,
    revError: queryResult[1].error,
    labels,
    figures,
    totalAmount: queryResult[1].isSuccess
      ? queryResult[1].data?.data?.data[0]?.amount
      : 0,
    //
    revWithFilterIsError: queryResult[2].isError,
    revWithFilterError: queryResult[2].error,
    revenueAmount,
    isRevSuccess, //to be used as a global variable to set loading state
    isRevLoading: queryResult[2].isLoading,
    //
    getFromAndToDate,
    duration,
    fromDate,
    toDate,
    //
    handleRevenueDataFetch,
  };
}
