import axios from "axios";
import Cookies from "universal-cookie";
import { Constants } from "../../utils/constants/main";
import { URLS } from "../../utils/constants/urls";

const cookies = new Cookies();

const APIAxios = axios.create({
  baseURL: URLS.BASE_API_URL,
});

APIAxios.interceptors.request.use(function (config) {
  config.headers["Authorization"] = cookies.get(
    Constants.Cookies.API_TOKEN_KEY
  );
  config.headers["Content-Type"] = "application/json";
  return config;
});

export function APISecuredAxios() {
  return ({ method, url, data }) => {
    return APIAxios({ method, url, data });
  };
}
