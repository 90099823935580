import { useLocation } from "react-router-dom";
import { useUserContext } from "../../context/context";
import { routes } from "../../utils/constants/route";
import { useEffect } from "react";

export const useSidebarLogic = () => {
  const { handleHeaderText } = useUserContext();

  const { pathname } = useLocation();

  useEffect(() => {
    handleHeaderText(pathname);
  }, [pathname, handleHeaderText]);

  const navItems = routes.USER;
  return { navItems, handleHeaderText, pathname };
};
