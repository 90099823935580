import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { useCustomMUIDataTable } from "./useCustomMUIDataTable";
import classes from "./customMuiDataTable.module.css";
import Space from "../Space";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import SelectButton from "../SelectButton/SelectButton";

function CustomMUIDataTable({
  tableData,
  columns,
  isError,
  error,
  isLoading,
  handleFilter = () => null,
  getFromAndToDate = () => null,
  colspan = 3,
}) {
  const {
    handleChangePage,
    slicedData,
    handleChangeRowsPerPage,
    errorFound,
    noDataFound,
    dataLoading,
    tableDataDisplay,
    //
    rowsPerPage,
    //
    page,
    //
    networkOpen,
    networkAnchorEl,
    selectedNetworkOption,
    networkItemList,
    handleNetworkButtonClick,
    handleNetworkMenuClose,
    handleNetworkSelect,
    //
    statusOpen,
    statusAnchorEl,
    selectedStatusOption,
    statusItemList,
    handleStatusButtonClick,
    handleStatusMenuClose,
    handleStatusSelect,
    //
    sortOpen,
    sortAnchorEl,
    selectedSortOption,
    sortItemList,
    handleSortButtonClick,
    handleSortMenuClose,
    handleSortSelect,
    //
    exportOpen,
    exportAnchorEl,
    selectedExportOption,
    exportItemList,
    handleExportButtonClick,
    handleExportMenuClose,
    handleExportSelect,
  } = useCustomMUIDataTable(tableData, columns, error, handleFilter, colspan);

  return (
    <div>
      <div className={classes.searchOption}>
        <div className={classes.searchOptionLeft}>
          <SelectButton
            selectedOption={selectedNetworkOption}
            open={networkOpen}
            handleSelect={handleNetworkSelect}
            anchorEl={networkAnchorEl}
            handleButtonClick={handleNetworkButtonClick}
            handleMenuClose={handleNetworkMenuClose}
            itemList={networkItemList}
            paddingRight={4}
          />
          <Space
            paddingLeft={0}
            paddingBottom={0}
            paddingRight={10}
            paddingTop={0}
          />
          <DateRangePicker getFromAndToDate={getFromAndToDate} />
          <Space
            paddingLeft={0}
            paddingBottom={0}
            paddingRight={10}
            paddingTop={0}
          />
          <SelectButton
            selectedOption={selectedStatusOption}
            open={statusOpen}
            handleSelect={handleStatusSelect}
            anchorEl={statusAnchorEl}
            handleButtonClick={handleStatusButtonClick}
            handleMenuClose={handleStatusMenuClose}
            itemList={statusItemList}
            paddingRight={4}
            marginLeft={1}
          />
          <Space
            paddingLeft={0}
            paddingBottom={0}
            paddingRight={10}
            paddingTop={0}
          />
          <span className={classes.sortBy}>Sort by:</span>
          <SelectButton
            selectedOption={selectedSortOption}
            open={sortOpen}
            handleSelect={handleSortSelect}
            anchorEl={sortAnchorEl}
            handleButtonClick={handleSortButtonClick}
            handleMenuClose={handleSortMenuClose}
            itemList={sortItemList}
            paddingRight={4}
            marginLeft={1}
            borderWidth={false}
          />
        </div>
        <div>
          <SelectButton
            selectedOption={selectedExportOption}
            open={exportOpen}
            handleSelect={handleExportSelect}
            anchorEl={exportAnchorEl}
            handleButtonClick={handleExportButtonClick}
            handleMenuClose={handleExportMenuClose}
            itemList={exportItemList}
            paddingRight={10}
            marginLeft={2}
          />
        </div>
      </div>
      <Space
        paddingLeft={0}
        paddingBottom={0}
        paddingRight={0}
        paddingTop={10}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.name}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? dataLoading()
              : isError
              ? errorFound()
              : slicedData.length > 0
              ? slicedData.map((row, index) => tableDataDisplay(row, index))
              : noDataFound()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default CustomMUIDataTable;
