import { useEffect, useState } from "react";
import { useFetches } from "../../hooks/useFetches";
import moment from "moment";
import { useQueryClient } from "react-query";

export function useHomeLogic() {
  const currentDate = moment();
  const count = 1;
  const [startDate, setStartDate] = useState(
    moment().subtract(count, "week").format("YYYY-MM-DD")
  );
  const [duration, setDuration] = useState("week");
  const queryClient = useQueryClient();

  const listOfEndpoints = [
    { queryKey: ["subscribe"], field: "subscribe" },
    { queryKey: [`subscribe/active/${startDate}`], field: "active" },
    { queryKey: [`subscribe/inactive/${startDate}`], field: "inactive" },
    {
      queryKey: [
        `revenue/${startDate}/${currentDate.format(
          "YYYY-MM-DD"
        )}/${duration}/?network=all&service=all`,
      ],
      field: "revenue",
    },
  ];

  const queryResult = useFetches(listOfEndpoints, true, {}, 1800000, 1800000);
  const [labels, figures, totalAmount] = queryResult[3].isSuccess
    ? queryResult[3].data?.data?.data.reduce(
        ([days, amounts, totalAmount], { day, amount }) => {
          days.push(day);
          amounts.push(amount);
          totalAmount += amount;
          return [days, amounts, totalAmount];
        },
        [[], [], 0]
      )
    : [[], []];
  const handleChange = (event) => {
    setDuration(event.target.value);
    setStartDate(
      currentDate.subtract(count, event.target.value).format("YYYY-MM-DD")
    );
  };

  useEffect(() => {
    listOfEndpoints.forEach((endpoint) => {
      queryClient.invalidateQueries(endpoint.queryKey);
    });
  }, [duration, queryClient]);

  return {
    duration,
    figures,
    labels,
    queryResult,
    totalAmount,
    handleChange,
  };
}
