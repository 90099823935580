import React from "react";
import { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as Papa from "papaparse";
import "jspdf-autotable";
import { TableCell, TableRow } from "@mui/material";
//
import classes from "./customMuiDataTable.module.css";
import Loader from "../Loader/Loader";
export function useCustomMUIDataTable(
  tableData = [],
  columns = {},
  error = "",
  handleFilter,
  colspan
) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //network
  const [networkOpen, setNetworkOpen] = useState(false);
  const [networkAnchorEl, setNetworkAnchorEl] = useState(null);
  const [networkItemList] = useState([
    { id: "all", value: "Networks" },
    { id: "mtn", value: "MTN" },
    { id: "vodafone", value: "Vodafone" },
    { id: "airteltigo", value: "AirtelTigo" },
  ]);
  const [selectedNetworkOption, setSelectedNetworkOption] = useState({
    id: "all",
    value: "Networks",
  });

  //status
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [statusItemList] = useState([
    { id: "all", value: "Status" },
    { id: "active", value: "Active" },
    { id: "inactive", value: "Inactive" },
    { id: "suspended", value: "Suspended" },
    { id: "deactivated", value: "Deactivated" },
  ]);
  const [selectedStatusOption, setSelectedStatusOption] = useState({
    id: "all",
    value: "Status",
  });

  //sort
  const [sortOpen, setSortOpen] = useState(false);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [sortItemList] = useState([
    { id: "newest", value: "Newest" },
    { id: "oldest", value: "Oldest" },
  ]);
  const [selectedSortOption, setSelectedSortOption] = useState({
    id: "newest",
    value: "Newest",
  });

  //export
  const [exportOpen, setExportOpen] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportItemList] = useState([
    { id: "excel", value: "Excel" },
    { id: "csv", value: "CSV" },
  ]);
  const [selectedExportOption] = useState({
    id: "export",
    value: "Export",
  });
  // network begins here
  const handleNetworkButtonClick = (event) => {
    setNetworkAnchorEl(event.currentTarget);
    setNetworkOpen(true);
  };

  const handleNetworkMenuClose = () => {
    setNetworkAnchorEl(null);
    setNetworkOpen(false);
  };

  const handleNetworkSelect = (selectedOption) => {
    handleFilter({ ...selectedOption, field: "network" });
    setSelectedNetworkOption(selectedOption);
    setNetworkAnchorEl(null);
    setNetworkOpen(false);
  };

  //status begins here
  const handleStatusButtonClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
    setStatusOpen(true);
  };

  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null);
    setStatusOpen(false);
  };

  const handleStatusSelect = (selectedOption) => {
    handleFilter({ ...selectedOption, field: "status" });
    setSelectedStatusOption(selectedOption);
    setStatusAnchorEl(null);
    setStatusOpen(false);
  };

  //sort begins here
  const handleSortButtonClick = (event) => {
    setSortAnchorEl(event.currentTarget);
    setSortOpen(true);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
    setSortOpen(false);
  };

  const handleSortSelect = (selectedOption) => {
    handleFilter({ ...selectedOption, field: "sort" });
    setSelectedSortOption(selectedOption);
    setSortAnchorEl(null);
    setSortOpen(false);
  };

  //export begins here
  const handleExportButtonClick = (event) => {
    setExportAnchorEl(event.currentTarget);
    setExportOpen(true);
  };

  const handleExportMenuClose = () => {
    setExportAnchorEl(null);
    setExportOpen(false);
  };

  const handleExportSelect = (selectedOption) => {
    switch (selectedOption?.id) {
      case "excel":
        const ws = XLSX.utils.json_to_sheet(tableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
          new Blob([excelBuffer], { type: "application/octet-stream" }),
          "data.xlsx"
        );
        break;

      case "csv":
        const csv = Papa.unparse(tableData);
        const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(csvBlob, "data.csv");
        break;

      default:
        break;
    }
    setExportAnchorEl(null);
    setExportOpen(false);
  };

  //others
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const slicedData = tableData.slice(startIndex, endIndex);
  const errorFound = () => {
    return (
      <TableRow>
        <TableCell colSpan={colspan}>
          <span className={classes.error}>{error?.message || "there"}</span>
        </TableCell>
      </TableRow>
    );
  };

  const noDataFound = () => {
    return (
      <TableRow>
        <TableCell colSpan={colspan}>
          <span className={classes.noData}>
            Sorry, no matching records found
          </span>
        </TableCell>
      </TableRow>
    );
  };

  const dataLoading = () => {
    return (
      <TableRow>
        <TableCell colSpan={5}>
          <Loader />
        </TableCell>
      </TableRow>
    );
  };

  const tableDataDisplay = (row, index) => {
    return (
      <TableRow key={++index}>
        {columns.map((column) => (
          <TableCell key={column.name}>
            {column.name === "status" || column.name === "state" ? (
              <span
                style={{
                  color:
                    row?.status.toString().toLowerCase() === "deactivated" ||
                    row?.state.toString().toLowerCase() === "expired"
                      ? "#DD0D0D"
                      : row?.status.toString().toLowerCase() === "inactive"
                      ? "#D98A0B"
                      : row?.status.toString().toLowerCase() === "suspended"
                      ? "#D98A0B"
                      : "#625DF5",
                }}
              >
                {row[column.name]}
              </span>
            ) : (
              row[column.name]
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return {
    slicedData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    errorFound,
    noDataFound,
    dataLoading,
    tableDataDisplay,
    //network here
    networkOpen,
    networkAnchorEl,
    networkItemList,
    selectedNetworkOption,
    handleNetworkButtonClick,
    handleNetworkMenuClose,
    handleNetworkSelect,
    //status here
    statusOpen,
    statusAnchorEl,
    statusItemList,
    selectedStatusOption,
    handleStatusButtonClick,
    handleStatusMenuClose,
    handleStatusSelect,
    //sort by here
    sortOpen,
    sortAnchorEl,
    selectedSortOption,
    sortItemList,
    handleSortButtonClick,
    handleSortMenuClose,
    handleSortSelect,
    //export here
    exportOpen,
    exportAnchorEl,
    selectedExportOption,
    exportItemList,
    handleExportButtonClick,
    handleExportMenuClose,
    handleExportSelect,
  };
}
