function Space({
  paddingLeft = 5,
  paddingRight = 5,
  paddingTop = 5,
  paddingBottom = 5,
}) {
  return (
    <div
      style={{
        paddingLeft: paddingLeft,
        paddingBottom: paddingBottom,
        paddingTop: paddingTop,
        paddingRight: paddingRight,
      }}
    ></div>
  );
}

export default Space;
