import { Menu, Popover } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button } from "@mui/material";
import classes from "./daterange.module.css";
import { MenuItem } from "@mui/material";
import ArrowDownTwo from "../../assets/images/svg/ArrowDownTwo";
import { useDateRangePickerLogic } from "./useDateRangePickerLogic";

function DateRangePicker({ getFromAndToDate = () => null }) {
  const {
    listAnchorEl,
    anchorEl,
    displayCalendar,
    fromDate,
    date,
    dateMenu,
    toDate,
    dateOptionList,
    dateChosen,
    onPopoverClose,
    onAdornmentClick,
    handleButtonClick,
    handleDateChange,
    handleMenuClose,
  } = useDateRangePickerLogic(getFromAndToDate);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        endIcon={<ArrowDownTwo />}
        className={classes.options}
      >
        <span className={classes.text}>Date range</span>
      </Button>
      <Menu open={dateMenu} anchorEl={listAnchorEl} onClose={handleMenuClose}>
        <div className={classes.datePreview}>
          <div>{`${date.charAt(0).toLocaleUpperCase()}${date.slice(1)}`}</div>
          <div>{dateChosen}</div>
          <hr />
        </div>
        {dateOptionList.map((item) => {
          return (
            <MenuItem
              key={item.key}
              onClick={() =>
                handleDateChange(item.key, item.dateFrom, item.dateStart)
              }
            >
              {item.key}
            </MenuItem>
          );
        })}
        <hr />
        <MenuItem value="custom" onClick={onAdornmentClick}>
          Custom date range
        </MenuItem>
      </Menu>

      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
        className={classes.popoverModal}
      >
        <div>
          <DateRange
            editableDateInputs={true}
            onChange={(event) =>
              handleDateChange(
                event.custom.key,
                event.custom.startDate,
                event.custom.endDate
              )
            }
            minDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            }
            maxDate={new Date()}
            showDateDisplay={false}
            moveRangeOnFirstSelection={false}
            ranges={[{ startDate: fromDate, endDate: toDate, key: "custom" }]}
          />
        </div>
        <div className={classes.okayButton}>
          <Button onClick={onPopoverClose}>Okay</Button>
        </div>
      </Popover>
    </>
  );
}

export default DateRangePicker;
