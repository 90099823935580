function ArrowBackSmall() {
  return (
    <svg
      width="24"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.292894 8.20711C-0.0976295 7.81658 -0.0976295 7.18342 0.292894 6.79289L6.65686 0.428932C7.04738 0.0384078 7.68055 0.0384078 8.07107 0.428932C8.46159 0.819457 8.46159 1.45262 8.07107 1.84315L2.41422 7.5L8.07107 13.1569C8.46159 13.5474 8.46159 14.1805 8.07107 14.5711C7.68055 14.9616 7.04738 14.9616 6.65686 14.5711L0.292894 8.20711ZM24 8.5L1 8.5V6.5L24 6.5V8.5Z"
        fill="#625DF5"
      />
    </svg>
  );
}

export default ArrowBackSmall;
