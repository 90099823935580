import { useServiceDetailsLogic } from "./useServiceDetailsLogic";
import CustomMUIDataTable from "../../components/CustomMUIDataTable/CustomMUIDataTable";

const columns = [
  {
    name: "subscriber",
    label: "Subscriber",
    options: {
      filter: true,
      sort: true,
      display: true,
    },
  },
  {
    name: "network",
    label: "Network",
    options: {
      filter: true,
      sort: true,
      display: true,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "state",
    label: "State",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
    },
  },
];

function ServiceDetails() {
  const {
    tableData,
    isError,
    isLoading,
    error,
    handleFilter,
    getFromAndToDate,
  } = useServiceDetailsLogic();
  return (
    <CustomMUIDataTable
      tableData={tableData}
      columns={columns}
      isLoading={isLoading}
      colspan={5}
      isError={isError}
      error={error}
      handleFilter={handleFilter}
      getFromAndToDate={getFromAndToDate}
    />
  );
}

export default ServiceDetails;
