function UserIcon({ fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.089"
      height="29.551"
      viewBox="0 0 27.089 29.551"
    >
      <path
        id="user"
        d="M-475.455-331.449c-2.971,0-5.139-.079-6.823-.249a13.366,13.366,0,0,1-4.132-.929,3.912,3.912,0,0,1-2.042-1.88,7.017,7.017,0,0,1-.548-3.1,7.007,7.007,0,0,1,.549-3.1,3.911,3.911,0,0,1,2.041-1.878,13.367,13.367,0,0,1,4.132-.928c1.684-.17,3.852-.249,6.823-.249s5.137.079,6.821.249a13.378,13.378,0,0,1,4.134.928,3.916,3.916,0,0,1,2.042,1.88,7.011,7.011,0,0,1,.547,3.1,7.022,7.022,0,0,1-.547,3.1,3.915,3.915,0,0,1-2.041,1.879,13.374,13.374,0,0,1-4.134.929C-470.318-331.528-472.485-331.449-475.455-331.449Zm0-9.85c-2.887,0-4.976.075-6.575.236a11.5,11.5,0,0,0-3.361.721,1.5,1.5,0,0,0-.847.716,4.957,4.957,0,0,0-.3,2.021,4.97,4.97,0,0,0,.3,2.02,1.5,1.5,0,0,0,.847.717,11.5,11.5,0,0,0,3.362.721c1.6.161,3.689.236,6.575.236s4.974-.075,6.574-.237a11.505,11.505,0,0,0,3.363-.721,1.5,1.5,0,0,0,.847-.716,4.978,4.978,0,0,0,.3-2.021,4.97,4.97,0,0,0-.3-2.018,1.5,1.5,0,0,0-.849-.719,11.51,11.51,0,0,0-3.362-.72C-470.481-341.224-472.57-341.3-475.455-341.3Zm0-4.925a7.4,7.4,0,0,1-7.388-7.388A7.4,7.4,0,0,1-475.455-361a7.4,7.4,0,0,1,7.388,7.387A7.4,7.4,0,0,1-475.455-346.225Zm0-12.312a4.93,4.93,0,0,0-4.926,4.924,4.931,4.931,0,0,0,4.926,4.926,4.931,4.931,0,0,0,4.926-4.926A4.93,4.93,0,0,0-475.455-358.537Z"
        transform="translate(489 361)"
        fill="#625df5"
      />
    </svg>
  );
}

export default UserIcon;
