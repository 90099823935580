function ArrowUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.405"
      height="17.577"
      viewBox="0 0 18.405 17.577"
    >
      <g
        id="Icon_feather-arrow-up"
        data-name="Icon feather-arrow-up"
        transform="translate(-6.086 -6.5)"
      >
        <path
          id="Path_2336"
          data-name="Path 2336"
          d="M18,23.077V7.5"
          transform="translate(-2.712 0)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_2337"
          data-name="Path 2337"
          d="M7.5,15.288,15.288,7.5l7.788,7.788"
          transform="translate(0 0)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default ArrowUpIcon;
