import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import welcomeImg from "../../assets/images/walapi-welcome.png";
import WelcomeImage from "../../components/Login/WelcomeImage";
import classes from "./welcomelayout.module.css";

function WelcomeLayout() {
  return (
    <div className={classes.container}>
      <Grid container spacing={0} className={classes.main}>
        <Grid item md={6} className={classes.image}>
          <WelcomeImage name={"login"} img={welcomeImg} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.login}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
}

export default WelcomeLayout;
