function ArrowDownTwo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.118"
      height="9.788"
      viewBox="0 0 17.118 9.788"
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M14.75,18.084l6.473-6.478a1.218,1.218,0,0,1,1.728,0,1.234,1.234,0,0,1,0,1.733l-7.334,7.339a1.221,1.221,0,0,1-1.687.036l-7.385-7.37a1.224,1.224,0,0,1,1.728-1.733Z"
        transform="translate(-6.188 -11.246)"
      />
    </svg>
  );
}

export default ArrowDownTwo;
