import { Grid } from "@mui/material";
import ServiceCard from "../../components/Card/ServiceCard";
import { useServiceLogic } from "./useServiceLogic";

function Service() {
  const { data, isError, isLoading, error } = useServiceLogic();
  if (isError) {
    return <p>{error.message}</p>;
  }
  if (isLoading) {
    return <p>loaging</p>;
  }

  return (
    <Grid container spacing={2}>
      {data.map((item) => {
        return (
          <Grid item xs={12} lg={6} key={item.id}>
            <ServiceCard service={item} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Service;
