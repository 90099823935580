import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import { CircularProgress, Box } from "@mui/material";
import Notification from "../../components/Notification";
import { PasswordInput } from "../../components/Form/FormsInputs";
import classes from "./resetpassword.module.css";
import { Link } from "react-router-dom";
import useResetPasswordLogic from "./useResetPasswordLogic";
// import ArrowBack from "../../assets/images/svg/ArrowBack";
import ArrowBackSmall from "../../assets/images/svg/ArrowBackSmall";

function ResetPassword() {
  const { state, handlers, fetchResponse, expiryTimestamp, success } =
    useResetPasswordLogic();
  const { register, errors, open, notificationMessage } = state;
  const { isLoading } = fetchResponse;
  const { handleSubmit, onSubmit, handleClose } = handlers;

  const Success = () => {
    return (
      <div className={classes.container}>
        <div>Password successfully been reset. </div>
        <Link to={"/login"} className={classes.link}>
          <Button
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
            className={classes.button}
          >
            Click here to login with new password
          </Button>
        </Link>
      </div>
    );
  };

  const ExpiredToken = () => {
    return (
      <div className={classes.container}>
        <div>Expired or Invalid Session</div>
        <Link to={"/login/reset-password"} className={classes.link}>
          <Button
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
            className={classes.button}
          >
            Click to start new session
          </Button>
        </Link>
        <Link to={"/login"} className={classes.link}>
          <span>
            <ArrowBackSmall />
            {/* <ArrowBack color={true} width="26.992" height="23.136" /> */}
          </span>
          &nbsp;
          <span>Back to login</span>
        </Link>
        <Notification
          severity="error"
          message={notificationMessage}
          openNotification={open}
          handleClose={handleClose}
        />
      </div>
    );
  };

  const ValidToken = () => {
    return (
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
        className={classes.container}
      >
        <div>
          <div className={classes.signin}>Reset password</div>
          <div>
            Enter new password to proceed. Session expires in
            <strong>
              <i> {expiryTimestamp}</i>
            </strong>
          </div>
        </div>
        <PasswordInput
          register={register}
          name="password"
          label="Password"
          error={errors?.password}
        />
        <PasswordInput
          register={register}
          name="confirm"
          label="Confirm Password"
          error={errors?.confirm}
        />
        <Button
          type="submit"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading}
          className={classes.button}
        >
          Submit
        </Button>
        <Link to={"/login"} className={classes.link}>
          <span>
            <ArrowBackSmall />
            {/* <ArrowBack color={true} width="26.992" height="23.136" /> */}
          </span>
          &nbsp;
          <span>Back to login</span>
        </Link>

        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: "absolute",
              top: "64%",
              left: "67%",
            }}
          />
        )}
        <Notification
          severity="error"
          message={notificationMessage}
          openNotification={open}
          handleClose={handleClose}
        />
      </Box>
    );
  };

  return (
    <>
      {success && Success()}
      {!success && expiryTimestamp > 0 && ValidToken()}
      {!success && expiryTimestamp <= 0 && ExpiredToken()}
    </>
  );
}

export default ResetPassword;
