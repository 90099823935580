import { useQuery } from "react-query";
import { APISecuredAxios } from "../libs/axios/securedAxios";

const fetchData = ({ queryKey }, data) => {
  if (Array.isArray(queryKey) && queryKey.length > 1) {
    const url = queryKey.join("/");
    return APISecuredAxios()({ method: "get", url, data });
  }
  return APISecuredAxios()({ method: "get", url: `${queryKey[0]}`, data });
};

export const useFetch = (
  key,
  enabled = false,
  data = {},
  cacheTime = 30000,
  staleTime = 30000,
  onSuccess = () => null,
  onError = () => null
) => {
  return useQuery(key, (queryKey) => fetchData(queryKey, data), {
    refetchOnWindowFocus: false,
    enabled: enabled,
    cacheTime: cacheTime,
    staleTime: staleTime,
    retry: false,
    onSuccess: onSuccess,
    onError: onError,
  });
};
