import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth";
import classes from "./PrivateRoutes.module.css";
import SideBar from "../sidebar/SideBar";
import ContextProvider from "../../context/context";
import Header from "../Header/Header";

function PrivateRoutes() {
  if (!isAuthenticated()) return <Navigate to={"/login"} replace />;
  return (
    <div className={classes.container}>
      <ContextProvider>
        <SideBar />
        <div className={classes.outlet}>
          <Header />
          <Outlet />
        </div>
      </ContextProvider>
    </div>
  );
}

export default PrivateRoutes;
