import { useMutation } from "react-query";
import { APISecuredAxios } from "../libs/axios/securedAxios";

const postUserData = ({ url, data, method }) => {
  return APISecuredAxios()({ method, url, data });
};
export const usePost = (onSuccess, onError) => {
  return useMutation(postUserData, {
    onSuccess: onSuccess,
    onError: onError,
  });
};
