import { Sidebar, Menu } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import classes from "./sidebar.module.css";
import { useSidebarLogic } from "./useSidebarLogic";

function SideBar() {
  const { navItems, pathname } = useSidebarLogic();
  return (
    <div className={classes.container}>
      <Sidebar width="300px" className={classes.sidebar}>
        <Menu className={classes.sidebar__nav} active={true}>
          {navItems?.map(({ name, Icon, id }) => {
            return (
              <div className={classes.sidebar__menu} key={id}>
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? classes.sidebar__active_link
                      : classes.sidebar__link
                  }
                  to={`/${id}`}
                  state={name}
                >
                  <div className={classes.sidebar__nav_item}>
                    {!!Icon && (
                      <Icon
                        fill={pathname === `/${id}` ? "#625df5" : ""}
                        stroke={pathname === `/${id}` ? "#625df5" : ""}
                      />
                    )}
                    <span>{name}</span>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </Menu>
      </Sidebar>
    </div>
  );
}

export default SideBar;
