import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import classes from "./profile.module.css";
import FilePdf from "../../assets/images/svg/FilePdf";
import { useProfileLogic } from "./useProfileLogic";

function Profile() {
  const { profile } = useProfileLogic();

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <Card
          sx={{
            minWidth: 450,
            border: "1px solid #EBEAED",
            borderRadius: "20px",
          }}
          variant="outlined"
        >
          <CardContent>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              Contact name
            </Typography>
            <div className={classes.textField}>{profile?.fullname}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              Contact email
            </Typography>
            <div className={classes.textField}>{profile?.email}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              Username
            </Typography>
            <div className={classes.textField}>{profile?.username}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              Company's name
            </Typography>
            <div className={classes.textField}>{profile?.company}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              Company's email
            </Typography>
            <div className={classes.textField}>{profile?.email}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              Mobile number
            </Typography>
            <div className={classes.textField}>{profile?.msisdn}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              ID number
            </Typography>
            <div className={classes.textField}>{profile?.idcard_no}&nbsp; </div>
            <Typography sx={{ fontSize: 14, fontWeight: "bold" }} gutterBottom>
              ID Type
            </Typography>
            <div className={classes.textField}>
              {profile?.idcard_type}&nbsp;{" "}
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <div className={classes.document}>
              <FilePdf /> &nbsp; &nbsp;<span> Document</span>
            </div>
            <div className={classes.view}>
              <Button size="small">View</Button>
            </div>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}

export default Profile;
