import { TextField, MenuItem } from "@mui/material";
import classes from "./forms.module.css";

export function TextInput(props) {
  const { register, error, name, autoFocus } = props;
  return (
    <>
      <label htmlFor={name} className={classes.label_field}>
        {name[0].toUpperCase() + name.slice(1)}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: true,
            message: "Field is required",
          },
          minLength: {
            value: 3,
            message: "Must be more than 3 characters",
          },
          pattern: {
            value: /^[a-zA-Z0-9@_-\s]*$/,
            message: "Unacceptable character(s) found",
          },
        })}
        error={!!error}
        fullWidth
        id="username"
        autoComplete="username"
        helperText={error?.message}
        className={classes.form__input}
        autoFocus={autoFocus}
        style={{ marginTop: "2px" }}
      />
    </>
  );
}

export function EmailInput(props) {
  const { register, error, name, label, autoFocus } = props;
  return (
    <>
      <label htmlFor={name} className={classes.label_field}>
        {label[0].toUpperCase() + label.slice(1)}
      </label>
      <TextField
        {...register(name, {
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address",
          },
          required: {
            value: true,
            message: "Email is required",
          },
          minLength: {
            value: 12,
            message: "Invalid email address. Too short",
          },
          maxLength: {
            value: 45,
            message: "Invalid email address. Too long",
          },
        })}
        error={!!error}
        fullWidth
        id="email"
        autoComplete="email"
        helperText={error?.message}
        className={classes.form__input}
        autoFocus={autoFocus}
        style={{ marginTop: "2px" }}
      />
    </>
  );
}

export function PasswordInput(props) {
  const { register, error, name, label } = props;
  return (
    <>
      <label htmlFor={name} className={classes.label_field}>
        {label}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: true,
            message: "Field is required",
          },
          minLength: {
            value: 8,
            message: "Must be more than 8 characters",
          },
          pattern: {
            value: /^(?=.*[a-zA-Z])(?=.*\d)[!@#$%^&*+(){ }?></a-zA-Z\d]{8,}$/,
            message: "Must be alphanumeric with no unacceptable character(s)",
          },
        })}
        error={!!error}
        fullWidth
        type="password"
        id={name}
        helperText={error?.message}
        autoComplete="current-password"
        className={classes.form__input}
        style={{ marginTop: "2px" }}
      />
    </>
  );
}

export function NumberInput(props) {
  const { register, error, name, label } = props;
  return (
    <TextField
      {...register(name, {
        required: {
          value: true,
          message: "Field is required",
        },
        minLength: {
          value: 3,
          message: "Must be more than 3 characters",
        },
        pattern: {
          value: /^[a-zA-Z0-9@_-\s]*$/,
          message: "Unacceptable character(s) found",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      label={label}
      autoComplete="username"
      helperText={error?.message}
      type="number"
      className={classes.form__input}
    />
  );
}

export function SelectInput(props) {
  const { register, error, name, label, options } = props;
  return (
    <TextField
      {...register(name, {
        required: {
          value: true,
          message: "Field is required",
        },
        minLength: {
          value: 3,
          message: "Must be more than 3 characters",
        },
        pattern: {
          value: /^[a-zA-Z0-9@_-\s]*$/,
          message: "Unacceptable character(s) found",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      label={label}
      autoComplete="username"
      helperText={error?.message}
      select
      defaultValue={options[0]?.value}
      className={classes.form__input}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function TextAreaInput(props) {
  const { register, error, name, label } = props;
  return (
    <TextField
      {...register(name, {
        required: {
          value: true,
          message: "Field is required",
        },
        minLength: {
          value: 3,
          message: "Must be more than 3 characters",
        },
        pattern: {
          value: /^[a-zA-Z0-9@_-\s]*$/,
          message: "Unacceptable character(s) found",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      label={label}
      multiline
      minRows={3}
      maxRows={6}
      helperText={error?.message}
      className={classes.form__input}
    />
  );
}
