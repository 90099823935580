import { useFetch } from "../../hooks/useFetch";

export function useServiceLogic() {
  const { isLoading, isError, error, data } = useFetch(
    "service",
    true,
    {},
    1800000,
    1800000
  );

  return { data: data?.data?.data || [], isError, isLoading, error };
}
