function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.482"
      height="18.413"
      viewBox="0 0 21.482 18.413"
    >
      <path
        id="Icon_metro-arrow-left"
        data-name="Icon metro-arrow-left"
        d="M17.859,23.748l7.672-7.672a1.534,1.534,0,0,0,0-2.17L17.859,6.233a1.534,1.534,0,1,0-2.17,2.17l5.053,5.053H6.033a1.534,1.534,0,1,0,0,3.069H20.742l-5.053,5.053a1.534,1.534,0,1,0,2.17,2.17Z"
        transform="translate(-4.499 -5.784)"
        fill="#625df5"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
