import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function AreaChart({ labels, figures = [], duration = "" }) {
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Amont GHS",
        data: figures,
        borderColor: `rgba(98, 93, 245)`,
        backgroundColor: `rgba(98, 93, 245, 0.1)`,
        pointBackgroundColor: "rgba(255, 255, 255)",
        pointBorderColor: "rgba(184, 181, 255)",
        pointBorderWidth: 2,
        pointRadius: 5,
        lineTension: 0.4,
      },
    ],
  };
  const options = {
    scales: {
      y: {
        suggestedMax: Math.max(...data.datasets[0].data),
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        position: "right",
        text: duration,
      },
    },
  };
  return <Line options={options} data={data} />;
}
