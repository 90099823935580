import { useState } from "react";
import moment from "moment";

export function useDateRangePickerLogic(getDateParams) {
  const [listAnchorEl, setListAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [date, setDate] = useState("today");
  const [dateMenu, setDateMenu] = useState(false);
  const [toDate, setToDate] = useState(new Date());
  const today = moment().toDate();

  const dateOptionList = [
    {
      dateFrom: moment().toDate(),
      dateStart: today,
      key: "Today",
    },
    {
      dateFrom: moment().subtract(7, "days").toDate(),
      dateStart: today,
      key: "Last 7 days",
    },
    {
      dateFrom: moment().subtract(30, "days").toDate(),
      dateStart: today,
      key: "Last 30 days",
    },
    {
      dateFrom: moment().subtract(90, "days").toDate(),
      dateStart: today,
      key: "Last 90 days",
    },
  ];

  const onPopoverClose = (_e, _reason) => {
    setDisplayCalendar(false);
    setAnchorEl(null);
    setListAnchorEl(null);
    setDateMenu(false);
  };

  const onAdornmentClick = (e) => {
    setAnchorEl(e.currentTarget);
    setDisplayCalendar(true);
  };

  const handleButtonClick = (event) => {
    setListAnchorEl(event.currentTarget);
    setDateMenu(true);
  };

  const getDuration = (from, to) => {
    const start = moment(from);
    const end = moment(to);
    const duration = Math.abs(end.diff(start, "days")) > 20 ? "month" : "week";
    return duration;
  };

  const handleDateChange = (key, fromDate, toDate) => {
    const duration = getDuration(fromDate, toDate);
    getDateParams({ fromDate, toDate, duration });

    setFromDate(fromDate);
    setToDate(toDate);
    setDate(key);
    if (key === "custom") return;
    setListAnchorEl(null);
    setDateMenu(false);
  };

  const handleMenuClose = (_event, _reason) => {
    setListAnchorEl(null);
    setDateMenu(false);
  };

  return {
    listAnchorEl,
    anchorEl,
    displayCalendar,
    fromDate,
    date,
    dateMenu,
    toDate,
    dateOptionList,
    dateChosen: `${moment(fromDate).format("MMMM D, YYYY")} - ${moment(
      toDate
    ).format("MMMM D, YYYY")}`,
    onPopoverClose,
    onAdornmentClick,
    handleButtonClick,
    handleDateChange,
    handleMenuClose,
  };
}
