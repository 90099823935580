import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";

export function useServiceDetailsLogic() {
  const [tableData, setTableData] = useState([]);
  const { servicename, keyword } = useParams();

  const onSuccess = (data) => {
    setTableData(data?.data?.data);
  };
  const queryKey = ["service", keyword];
  const { isSuccess, isLoading, isError, error, data } = useFetch(
    queryKey,
    true,
    {},
    30000,
    30000,
    onSuccess
  );

  function filterDataByPeriod(from = "", to = "", data = []) {
    const startDate = new Date(from);
    const endDate = new Date(to);

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });

    return filteredData;
  }

  const getFromAndToDate = (values) => {
    if (!isSuccess) return;

    const { fromDate, toDate } = values;
    const filteredData = filterDataByPeriod(fromDate, toDate, data.data?.data);

    setTableData(filteredData);
  };

  const handleFilter = (parameter) => {
    console.log(parameter);
    let filteredData = [...data?.data?.data];
    const field = parameter.field.toLowerCase();
    if (field === "network" && parameter.id !== "all") {
      filteredData = filteredData.filter(
        (item) => item.network?.toLowerCase() === parameter.id.toLowerCase()
      );
    }

    if (field === "status" && parameter.id !== "all") {
      filteredData = filteredData.filter(
        (item) => item.status?.toLowerCase() === parameter.id.toLowerCase()
      );
    }

    if (field === "sort") {
      if (parameter.id === "newest") {
        filteredData.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
      } else if (parameter.id === "oldest") {
        filteredData.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
      }
    }
    setTableData(filteredData);
  };
  return {
    servicename,
    tableData,
    isLoading,
    isError,
    error,
    //
    handleFilter,
    getFromAndToDate,
  };
}
