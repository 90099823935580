import classes from "./resendpassword.module.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import useResendPasswordLogic from "./useResendPasswordLogic";
// import ArrowBack from "../../assets/images/svg/ArrowBack";
import ArrowBackSmall from "../../assets/images/svg/ArrowBackSmall";

function ResendPassword() {
  const { email } = useResendPasswordLogic();
  return (
    <Box noValidate sx={{ mt: 1 }} className={classes.container}>
      <div>
        <div className={classes.signin}>Check your email</div>
        <span>
          An email is sent to{" "}
          <strong>
            <i>{email}</i>
          </strong>
          , open the email and follow the link to reset your password
        </span>
        <div className={classes.resend}>
          <p>Email not received?</p>&nbsp;
          <Link to={"/login/reset-password"}>
            <strong>Resend</strong>
          </Link>
        </div>
      </div>

      <Link to={"/login"} className={classes.link}>
        <span>
          <ArrowBackSmall />
          {/* <ArrowBack color={true} width="26.992" height="23.136" /> */}
        </span>
        &nbsp;
        <span>Back to login</span>
      </Link>
    </Box>
  );
}

export default ResendPassword;
