import { createContext, useContext, useState } from "react";
import { getUserDetailsCookie } from "../utils/auth";
import { IconButton } from "@mui/material";
import ArrowBack from "../assets/images/svg/ArrowBack";
import { useNavigate } from "react-router-dom";
import classes from "./context.module.css";

const DataContext = createContext();

export const useUserContext = () => {
  return useContext(DataContext);
};

function ContextProvider({ children }) {
  const [headerText, setHeaderText] = useState();
  const [userdetails] = useState(() => getUserDetailsCookie());
  const navigate = useNavigate();
  const greetings = (fullname) => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
      return `Good morning ${fullname}`;
    } else if (curHr < 18) {
      return `Good afternoon ${fullname}`;
    } else {
      return `Good evening ${fullname}`;
    }
  };

  const headerTextComponent = (name = "") => {
    return (
      <div className={classes.headerTextComponent}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <span>{name}</span>
      </div>
    );
  };

  const handleHeaderText = (page) => {
    const text = page.slice(1);
    if (page.toLowerCase() !== "/") {
      setHeaderText(`${text.charAt(0).toUpperCase()}${text.slice(1)}`);
      return;
    }
    setHeaderText(greetings(userdetails?.fullname));
  };

  return (
    <DataContext.Provider
      value={{ headerText, headerTextComponent, handleHeaderText }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default ContextProvider;
