import ArrowUpIcon from "../../assets/images/svg/ArrowUpIcon";
import classes from "./card.module.css";

function CustomCard({
  header,
  helperHeader,
  color,
  count,
  border,
  arrow = false,
  duration = "",
  currency,
  iconColor,
}) {
  return (
    <article
      className={classes.card}
      style={{
        "--custom-background-color": color,
        "--custom-icon-background-color": iconColor || "#ffffff",
        "--custom-border-width": border,
      }}
    >
      <header>
        <h2 className={classes.header}>{header}</h2>
        <p className={classes.helperHeader}>{helperHeader}</p>
      </header>
      <section>
        <p className={classes.count}>
          <sup>{currency}</sup>
          {count}
        </p>
      </section>
      {duration !== "" ? (
        <footer className={classes.footer}>
          {arrow && (
            <div className={classes.icon}>
              <ArrowUpIcon />
            </div>
          )}
          <p className={classes.duration}>{duration}</p>
        </footer>
      ) : (
        ""
      )}
    </article>
  );
}

export default CustomCard;
